@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
@import '../node_modules/@glidejs/glide/src/assets/sass/glide.core';
@import 'components/mixins.scss';
@import 'components/animations.scss';
@import 'components/page-loading.scss';
@import 'components/modal.scss';
@import 'components/lightbox-viewer.scss';
@import 'components/lightbox-picture-demo.scss';
@import 'components/quiz.scss';
@import 'components/accordion.scss';
@import 'components/image-gallery.scss';

* {
	transition: all 0.25s linear;
	transition:
		width 0s,
		height 0s,
		min-width 0s,
		min-height 0s,
		max-width 0s,
		max-height 0s;
	scroll-behavior: smooth;
}

.hidden-mobile {
	@media (max-width: 767.9px) {
		display: none !important;
	}
}

.hidden-desktop {
	@media (min-width: 767.9px) {
		display: none !important;
	}
}

html {
	font-family: 'Poppins', sans-serif;
	font-size: 18px;
	line-height: 1.5;
	color: #ffffff;
	overflow-x: hidden;
	touch-action: pan-y pinch-zoom;
}

.lightbox-overlay {
	position: absolute;
	background: transparent;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;
}

body {
	margin: 0;
	max-width: 100vw;
	overflow-x: hidden !important;
	touch-action: pan-y pinch-zoom;
	-webkit-overflow-scrolling: touch;
}

body.modal-open {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overscroll-behavior: none;

    @supports (-webkit-touch-callout: none) {
        position: fixed;
        width: 100%;
        height: 100vh;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }
}

.container {
	max-width: 1140px;
	width: 100%;
	margin: 0 auto;
	overflow-x: clip;
}

.hidden {
	display: none !important;
}

.visibility-hidden {
	visibility: hidden;
}

.no-scroll {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overscroll-behavior: none;

    @supports (-webkit-touch-callout: none) {
        position: fixed;
        width: 100%;
        height: 100vh;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }

    .modal__body,
    .page-section {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }
}

body.no-scroll {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overscroll-behavior: none;

    @supports (-webkit-touch-callout: none) {
        position: fixed;
        width: 100%;
        height: 100vh;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }
}

.modal {
    &__body {
        max-height: 100%;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        overscroll-behavior-y: contain;
    }
}

.page-loading__spinner,
.page-loading__spinner div {
	box-sizing: border-box;
}
.page-loading__spinner {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.page-loading__spinner div {
	display: inline-block;
	position: absolute;
	left: 8px;
	width: 16px;
	background: rgb(252, 196, 0);
	-webkit-animation: loading-spinner 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
	animation: loading-spinner 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.page-loading__spinner div:nth-child(1) {
	left: 8px;
	-webkit-animation-delay: -0.24s;
	animation-delay: -0.24s;
}
.page-loading__spinner div:nth-child(2) {
	left: 32px;
	-webkit-animation-delay: -0.12s;
	animation-delay: -0.12s;
}
.page-loading__spinner div:nth-child(3) {
	left: 56px;
	-webkit-animation-delay: 0s;
	animation-delay: 0s;
}
@-webkit-keyframes loading-spinner {
	0% {
		top: 8px;
		height: 64px;
	}
	50%,
	100% {
		top: 24px;
		height: 32px;
	}
}
@keyframes loading-spinner {
	0% {
		top: 8px;
		height: 64px;
	}
	50%,
	100% {
		top: 24px;
		height: 32px;
	}
}

.main-header {
	background: #000;
	transition: background 0s;
	background-size: cover;
	position: relative;
	height: 100dvh;

	.container {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		gap: 30px;
		height: 100%;
	}

	&__content {
		display: flex;
		position: absolute;
		z-index: 250;
		right: 0;
		top: 50%;
		transform: translate(10%, -50%);
		right: calc((100% - 1140px) / 2 + 25px);
		flex-direction: column;
		align-items: center;
		max-width: 480px;
		margin-right: auto;
		margin-left: auto;
		padding: 25px;
		background: rgba(0, 0, 0, 0.5);
		border-radius: 10px;
		
		@media (min-width: 768px) {
			/* right: 0; */
		}

		&--mobile {
			position: static;
		}

		@media (max-width: 767.9px) {
			top: 0;
			right: 50%;
			transform: translateX(50%);
			bottom: 0;
			width: 100%;
			max-width: 100vw;
			padding-top: 70px;
			box-sizing: border-box;
		}

		&--fullscreen {
			@media (min-width: 768px) {
				bottom: auto;
				padding-top: 5px;
				left: 0;
				transform: translate(0, -50%);
				top: 20%;
				right: 0;
			}

			@media (max-width: 767.9px) {
				bottom: auto;
				padding-top: 5px;
			}
		}

		&--bottom {
			@media (max-width: 767.9px) {
				top: 20%;
				right: 50%;
				transform: translateX(50%);
				width: 90%;
				max-width: 90vw;
				bottom: auto;
				padding: 10px;
			}
		}
	}

	&__title {
		text-align: center;
		margin: 0 auto 20px;
		font-size: 30px;
		line-height: 1.1;
		letter-spacing: 3px;
		font-weight: 600;
		content-visibility: auto;
		contain-intrinsic-size: auto 50px;
		will-change: contents;
		backface-visibility: hidden;
		transform: translateZ(0);
		opacity: 0;
		transform: translateY(20px);
		transition: opacity 0.3s, transform 0.3s;
	}

	&__description {
		margin: 0 auto 28px;
		line-height: 26px;
		font-size: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 10px;

		@media (max-width: 767.9px) {
			margin-top: auto;
		}
	}

	&__buttons {
		padding: 13px 24px;
		border-radius: 10px;
		box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.1);
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 10px;
		width: 90%;
		margin: 0 auto;

		@media (max-width: 767.9px) {
			padding: 10px 15px;
		}

		&-row {
			display: flex;
			flex-wrap: wrap;
			gap: 20px;
		}

		.button {
			flex-shrink: 0;
			display: block;
			width: 100%;
		}
	}
}

.button-secondary,
.btn--secondary {
	border: 3px solid linear-gradient(61deg, rgb(252, 196, 0), rgb(255, 190, 80));
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	padding: 15px 25px;
	border-radius: 10px;
	box-shadow: 0 2.6666666666666665px 8px 0px rgba(151, 118, 0, 0.79);
	letter-spacing: 2px;
	margin-left: auto;
	margin-right: auto;
	text-transform: uppercase;
	font-weight: 400;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}

.button-cta,
.btn--primary {
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: rgb(17, 17, 17) !important;
	text-decoration: none;
	-webkit-text-fill-color: rgb(17, 17, 17);
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	background: linear-gradient(61deg, rgb(252, 196, 0), rgb(255, 190, 80));
	font-size: 16px;
	padding: 15px 25px;
	border-radius: 10px;
	box-shadow: 0 2.6666666666666665px 8px 0px rgba(151, 118, 0, 0.79);
	letter-spacing: 2px;
	margin-left: auto;
	margin-right: auto;
	text-transform: uppercase;
	font-weight: 400;
	cursor: pointer;
	position: relative;
	overflow: hidden;

	&:hover {
		opacity: 0.5;
	}

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: -100%;
		width: 40px;
		height: 100%;
		background: linear-gradient(
			75deg,
			transparent,
			rgba(255, 255, 255, 0.75),
			transparent
		);
		transform: skewX(-35deg);
		-webkit-animation: slide 2s infinite;
		animation: slide 2s infinite;
	}

	@-webkit-keyframes slide {
		0% {
			left: -100%;
		}
		50% {
			left: 120%;
		}
		100% {
			left: 120%;
		}
	}

	@keyframes slide {
		0% {
			left: -100%;
		}
		50% {
			left: 120%;
		}
		100% {
			left: 120%;
		}
	}

	&--icon-arrow {
		flex-direction: row-reverse;
		gap: 5px;

		&::after {
			display: block;
			content: '';
			width: 17px;
			height: 17px;
			background: url('/images/icon-btn-arrow.svg') no-repeat center center;
		}
	}
}

.button-secondary {
	border: 3px solid rgb(255, 190, 80);
	background: transparent;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	text-decoration: none;
	-webkit-text-fill-color: rgb(255, 190, 80);
	color: rgb(255, 190, 80);
	padding: 15px 25px;
	border-radius: 10px;
	box-shadow: 0 2.6666666666666665px 8px 0px rgba(151, 118, 0, 0.79);
	letter-spacing: 2px;
	margin-left: auto;
	margin-right: auto;
	text-transform: uppercase;
	font-weight: 400;
	cursor: pointer;
	position: relative;
	overflow: hidden;

	&:hover, :not(:active) {
		background: rgb(255, 190, 80);
		color: rgb(17, 17, 17) !important;
		-webkit-text-fill-color: rgb(17, 17, 17);
	}

	@media (max-width: 767.9px) {
		padding: 10px;
	}
}

.about-lamp {
	color: rgb(17, 17, 17);

	.container {
		display: flex;
		align-items: center;
		padding-top: 65px;
		padding-bottom: 60px;
		gap: 25px;

		@media (max-width: 767.9px) {
			flex-direction: column;
			width: 95%;
			max-width: 500px;
			align-items: center;
			padding-top: 20px;
		}
	}

	&__description {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		align-self: flex-start;
		padding-top: 60px;
		max-width: 360px;

		@media (max-width: 767.9px) {
			width: 100%;
			margin-left: auto;
			margin-right: auto;
			padding-top: 20px;
		}
	}

	&__title {
		text-transform: uppercase;
		text-align: left;
		line-height: 1.4;
		letter-spacing: 4px;
		font-size: 26px;
		margin: 0 auto 24px 0;
		font-weight: 500;
		padding-right: 40px;
	}

	&__text {
		margin: 0 auto;
		font-size: 15px;
		line-height: 28px;
		letter-spacing: -0.25px;
		font-weight: 300;
		text-align: left;

		@media (max-width: 767.9px) {
			font-size: 18px;
			letter-spacing: 0.25px;
			line-height: 1.5;
		}
	}

	&__image {
		@media (max-width: 767.9px) {
			width: 100%;
			margin-left: auto;
			margin-right: auto;
			height: auto;
		}
	}
}

.benefits {
	background-color: rgb(49, 42, 38);

	.container {
		display: flex;
		align-items: center;
		gap: 25px;
		padding-top: 40px;
		padding-bottom: 63px;

		@media (max-width: 767.9px) {
			flex-direction: column;
			width: 100%;
			max-width: 500px;
			align-items: center;
			padding-top: 20px;
		}
	}

	&__video {
		display: block;
		flex-shrink: 0;

		@media (max-width: 767.9px) {
			width: 95%;
			margin-left: auto;
			margin-right: auto;
		}
	}

	&__description {
		align-self: flex-start;
		
		@media (max-width: 767.9px) {
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	&__title {
		color: #ffffff;
		font-size: 26px;
		letter-spacing: 4.5px;
		line-height: 1.4;
		font-weight: 400;
		margin: 14px 0 10px auto;
		padding-left: 45px;

		@media (max-width: 767.9px) {
			margin: 34px auto 10px;
			text-align: center;
			padding: 0;
		}
	}

	&__list {
		display: flex;
		flex-direction: column;
		gap: 5px;
		padding-left: 30px;
		margin-bottom: 28px;

		@media (max-width: 767.9px) {
			gap: 15px;
		}
	}

	&__item {
		color: #ffffff;
		font-size: 17px;
		font-weight: 300;
		line-height: 28px;
		letter-spacing: -0.25px;
		text-align: left;
		padding-left: 5px;

		strong {
			margin-right: 1px;
			font-weight: 600;
			letter-spacing: -0.5px;

			@media (max-width: 767.9px) {
				font-size: 18px;
				letter-spacing: 0.5px;
				line-height: 1.6;
			}
		}

		@media (max-width: 767.9px) {
			font-size: 18px;
			letter-spacing: 0.5px;
			line-height: 1.6;
		}
	}
}

.lightbox-viewer {
	background: #fff;
}

.piclight-details {
	background: url('/images/faq-bg.jpg') no-repeat;
	background-position-x: left;
	background-position-y: bottom;
	background-size: cover;
	padding-top: 46px;
	padding-bottom: 92px;
	display: flex;
	align-items: stretch;
	position: relative;

	&::before {
		position: absolute;
		content: '';
		display: block;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.25);
		z-index: 1;
	}

	@media (max-width: 767.9px) {
		background: url('/images/faq-bg-mob.jpg') no-repeat;
		background-position-x: center;
		background-position-y: bottom;
		background-size: cover;
		padding-bottom: 100vw;
	}

	.container {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		flex-wrap: wrap;
		z-index: 2;

		@media (max-width: 767.9px) {
			flex-direction: column;
			width: 95%;
			max-width: 500px;
			align-items: center;
			padding-top: 20px;
		}
	}

	.section-title {
		text-align: center;
		font-size: 26px;
		line-height: 1.4;
		letter-spacing: 0.1em;
		font-weight: 400;
		margin: 0 auto 9px;
		padding: 5px;
	}

	&__left {
		align-self: flex-start;
		width: 50%;

		@media (max-width: 767.9px) {
			width: 100%;
		}
	}

	&__right {
		width: 50%;
		background: rgba(19, 19, 19, 0.85);
		padding: 20px;
		border-radius: 12px;
		margin-bottom: 30px;

		@media (max-width: 767.9px) {
			width: 90%;
			margin-left: auto;
			margin-right: auto;
		}
	}

	&__button-wrapper {
		width: 100%;
		margin-top: auto;

		@media (max-width: 767.9px) {
			margin-top: 20px;
			margin-bottom: auto;
		}
	}

	&__accordions {
		transform: translateX(100%);
		opacity: 0;
		transition:
			transform 0.5s ease,
			opacity 0.5s ease;
	}

	&__accordions--visible {
		transform: translateX(0);
		opacity: 1;
	}
}

.gallery {
	background: rgb(49, 42, 38);
	padding-top: 55px;
	padding-bottom: 120px;

	.container {
		overflow-x: visible;
	}

	.section-title {
		text-align: center;
		font-size: 26px;
		line-height: 1.4;
		letter-spacing: 0.1em;
		font-weight: 400;
		margin: 0 auto 25px;
		padding: 5px;
	}
}

#cookies-notification {
	font-size: 15px;
  
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
  
	width: 100%;
	padding: 15px 10px;
  
	text-align: center;
  
	color: #fff;
	background-color: rgba(1, 7, 38, 0.6);
	z-index: 2000;
  }
  
  #cookies-notification .container {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-between;
  
	-moz-text-align-last: left;
	text-align-last: left;
	width: 95vw; 
	max-width: 400px;
  }
  
  #accept-cookies-btn {
	margin-bottom: 0;
	padding: 5px 15px;
  }
  
  #cookies-notification p {
	margin: 0;
	padding: 0;
  
	text-align: left;
  }
  
  
#cookies-notification .btn-accept-cookies {
	font-size: 15px;
	font-weight: 400;
	line-height: 18px;
  
	display: flex;
	align-items: center;
	justify-content: center;
  
	margin-bottom: 25px;
	padding: 8px 16px;
  
	cursor: pointer;
	letter-spacing: 0.78px;
  
	color: #f3f3f3;
	border: 1px solid rgba(255, 255, 255, 0.2);
	border-radius: 20px;
	outline: none;
	background: rgba(1, 7, 38, 0.2);
  
	gap: 6px;
	-webkit-backdrop-filter: blur(8px);
	backdrop-filter: blur(8px);
  }