.lightbox-demo-picture {
	padding-top: 63px;
	padding-bottom: 34px;

	&__image-wrapper {
		position: relative;
		width: 85%;
		margin-left: auto;
		margin-right: auto;
		height: auto;
		overflow: hidden;
	}

	&__image {
		width: 100%;
		height: auto;
		transition: opacity 0.5s ease-in-out;

		&--off {
			opacity: 1;
		}

		&--on {
			opacity: 0;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	.button-cta {
		min-width: 150px;
		margin-bottom: 10px;
		margin-top: 5px;
	}
}
