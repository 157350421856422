.lightbox-viewer-container {
	position: relative;
	width: 100%;
	height: 100%;
	min-height: 550px;
	overflow: hidden;

	canvas {
		transform: translateX(-50%);
		position: relative;
		left: 50%;

		@media (max-width: 767.9px) {
			transform: translate(-50%, 0);
		}
	}
}

.cropper-modal {
	background: transparent !important;
	opacity: 1;
}

.modal {
	position: fixed;
	z-index: 1000;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	transform: none;
	overflow-y: scroll;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: 100%;
	max-width: 100vw;
	max-height: 100vh;
	box-sizing: border-box;
	padding: 75px 20px 30px;
	color: #fff;
	background: #131313;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
	gap: 20px;	

	&__container {
		width: 90%;
		max-width: 500px;
		max-height: 85vh;
		height: 500px;
		max-height: 85vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;

		@media (max-width: 767.9px) {
			height: 400px;
			max-height: 85vh;
			width: 85%;
		}
	}

	@media (max-width: 767.9px) {
		padding: 30px 15px 25px;
	}

	&::-webkit-scrollbar {
		display: none;
	}

	-ms-overflow-style: none;
	scrollbar-width: none;

	&--notification {
		text-align: center;
		padding: 10px;

		.modal__button {
			margin-top: auto;
			margin-top: 20px;
			padding: 15px 35px !important;
			font-size: 18px;
		    letter-spacing: 0.5px;
		}

		p {
			margin-bottom: 10px !important;
		}

		.modal__title {
			margin-top: 10px;
		}
	}

	&__content {
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: space-around;
		width: 80%;		
		max-width: 400px;
		padding: 20px;
		content: '\e681';
		text-align: center;
		border-radius: 5px;
		gap: 0;

		p {
			line-height: 1.5;
			margin-bottom: 20px;

			strong {
				color: rgb(252, 196, 0);
				margin-left: 5px;
				margin-right: 5px;
			}
		}
	}

	&__button {
		margin-top: auto;
		padding: 10px 20px;
		cursor: pointer;
		color: #131313;
		border: none;
		border-radius: 5px;
		background-color: rgb(252, 196, 0);

		&:hover {
			background-color: rgb(206 160 0);
		}
	}
}

.cropper-container {
	width: 100% !important;
	max-height: 70vh !important;
}

.image-editor {
	height: 100%;
	width: 100%;
	padding: 30px 15px 25px;
	box-sizing: border-box;

	.modal__container {
		max-height: 600px;
		height: 90%;
		overflow-x: hidden !important;
		max-width: 500px;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
	}
	
	&__image {
		display: block;
		max-width: 100%;
		margin-bottom: 20px;
	}

	&__image-wrapper {
		display: flex;
		align-items: center;
		flex-direction: column;
		width: 100%;
		border: 1px dashed rgba(255,255,255,0.75);
		overflow: hidden !important;
	}

	&__image {
		width: 100%;
		max-width: 100%;
		height: auto;
	}

	&__control {
		margin-bottom: 10px;
	}

	&__label {
		font-weight: bold;
		display: block;
		margin-bottom: 5px;
	}

	&__range {
		width: 100%;
	}

	&__button {
		font-size: 16px;
		display: block;
		width: 100%;
		max-width: 400px;
		margin-top: auto;
		padding: 10px;
		cursor: pointer;
		color: #131313;
		border: none;
		border-radius: 5px;
		background-color: rgb(252, 196, 0);

		&:hover {
			background-color: rgb(206, 106, 0);
		}
	}

	&__images {
		display: flex;
		flex-grow: 1;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&__example {
		display: flex;
		align-items: center;
		flex-direction: column;
		order: -1;
		width: auto;
		min-width: 320px;
		margin-bottom: 15px;
	}

	&__example-description {
		font-size: 16px;
		margin-top: 0;
		letter-spacing: 0.5px;
	}

	&__description {
		margin-bottom: 10px;
	}

	&__example-image {
		max-width: 100%;
	}

	&__controls {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		gap: 40px;
	}

	&__range {
		width: 100%;
		height: 8px;
		transition: opacity 0.2s;
		opacity: 0.7;
		border-radius: 12px;
		outline: none;
		background: linear-gradient(
			to right,
			#596bc1 0%,
			#596bc1 50%,
			#dcdcdc 50%,
			#dcdcdc 100%
		);
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;

		&:hover {
			opacity: 1;
		}

		&::-webkit-slider-runnable-track {
			width: 100%;
			height: 8px;
			cursor: pointer;
			border-radius: 12px;
			background: transparent;
		}

		&::-webkit-slider-thumb {
			width: 20px;
			height: 20px;
			margin-top: -6px;
			cursor: pointer;
			border: 2px solid #1e1e32;
			border-radius: 50%;
			background: #596bc1;
			-webkit-appearance: none;
		}

		&:focus::-webkit-slider-runnable-track {
			background: transparent;
		}

		&::-moz-range-track {
			width: 100%;
			height: 8px;
			cursor: pointer;
			border-radius: 12px;
			background: transparent;
		}

		&::-moz-range-thumb {
			width: 20px;
			height: 20px;
			cursor: pointer;
			border: 1px solid #1e1e32;
			border-radius: 50%;
			background: #596bc1;
		}

		&::-ms-track {
			width: 100%;
			height: 8px;
			cursor: pointer;
			color: transparent;
			border-color: transparent;
			background: transparent;
		}

		&::-ms-fill-lower {
			border-radius: 12px;
			background: #596bc1;
		}

		&::-ms-fill-upper {
			border-radius: 12px;
			background: #dcdcdc;
		}

		&::-ms-thumb {
			width: 20px;
			height: 20px;
			cursor: pointer;
			border: 1px solid #1e1e32;
			border-radius: 50%;
			background: #596bc1;
		}

		&:focus::-ms-fill-lower {
			background: #dcdcdc;
		}

		&:focus::-ms-fill-upper {
			background: #94a6f8;
		}
	}
}

@media (min-width: 768px) {
	.image-editor {
		&__image-wrapper {
			width: 100%;
			max-width: 500px;
			height: auto;
			max-height: 500px;
			margin-left: auto;
			margin-right: auto;

			@media (max-width: 767.9px) {
				width: 100%;
				max-width: 300px;
				height: auto;
				max-height: 300px;
				margin-left: auto;
				margin-right: auto;
			}
		}

		&__example {
			width: 50%;
		}
	}
}

.cropper {
	&-face {
		border: 1px dashed rgba(255, 255, 255, 0.5) !important;
		background: transparent !important;
	}

	&-center-square {
		position: absolute;
		box-sizing: border-box;
		pointer-events: none;
		border: 3px dotted rgba(0, 205, 0, 0.75);
		height: 80% !important;
		width: 80% !important;
		top: 10% !important;
		left: 10% !important;
	}

	&-bg {
		opacity: 1 !important;
	}
}

.light-switch {
	position: absolute;
	z-index: 100;
	left: calc((100% - 100vw) / 2 + 25px);
	bottom: 25px;
	display: flex;
	align-items: center;
	align-self: start;
	flex-direction: column;
	padding: 10px;
	cursor: pointer;
	transition: opacity 0.25s ease;
	border-radius: 12px;
	background-color: rgba(0, 0, 0, 0.5);

	@media (max-width: 767.9px) {
		left: 15px;
		bottom: 65px;
	}

	h2 {
		font-size: 16px;
		line-height: 1.3;
		max-width: 120px;
		margin: 0;
		margin-bottom: 10px;
		text-align: center;
		color: rgba(255, 255, 255, 0.75);
	}

	&-button {
		font-size: 0;
		width: 70px;
		height: 70px;
		cursor: pointer;
		border: none;
		border-radius: 12px;
		background: rgba(0, 0, 0, 0.25);
		background: url(../images/btn-light-on.png) no-repeat center center;
		background-size: contain;
		opacity: 0.7;

		@media (max-width: 767.9px) {
			width: 60px;
			height: 60px;
		}

		&--off {
			background: url(../images/btn-light-off.png) no-repeat center center;
			background-size: contain;
		}
	}

	&:hover {
		opacity: 0.5;
	}
}

.lightbox-fullscreen-btn {
	cursor: pointer;
	position: absolute;
	z-index: 100;
	right: calc((100% - 1140px) / 2 + 25px);
	bottom: 25px;
	display: flex;
	align-items: center;
	align-self: start;
	flex-direction: column;
	padding: 20px;
	cursor: pointer;
	transition: opacity 0.25s ease;
	border-radius: 12px;
	background-color: rgba(0, 0, 0, 0.5);

	@media (max-width: 767.9px) {
		right: 15px;
		bottom: 15px;
	}

	h2 {
		font-size: 16px;
		line-height: 1.3;
		max-width: 120px;
		margin: 0;
		margin-bottom: 10px;
		text-align: center;
		color: rgba(255, 255, 255, 0.75);
	}

	&-button {
		font-size: 0;
		width: 40px;
		height: 40px;
		cursor: pointer;
		border: none;
		background: rgba(0, 0, 0, 0.25);
		background: url(../images/fullscreen-on.png) no-repeat center center;
		background-size: contain;
		opacity: 0.7;
		padding: 20px;

		@media (max-width: 767.9px) {
			width: 40px;
			height: 40px;
		}

		&--off {
			background-image: url(../images/fullscreen-off.png);
		}
	}

	&:hover {
		opacity: 0.5;
	}
}

.lightbox-next-btn {
	font-size: 0;
	cursor: pointer;
	position: absolute;
	z-index: 100;
	left: 50%;
	transform: translate(-50%);
	bottom: 25px;
	display: flex;
	align-items: center;
	align-self: start;
	flex-direction: column;
	padding: 20px;
	cursor: pointer;
	transition: opacity 0.25s ease;
	border-radius: 12px;
	background-color: rgba(0, 0, 0, 0.5);

	@media (max-width: 767.9px) {
		bottom: 65px;
	}

	&-button {
		font-size: 0;
		width: 20px;
		height: 20px;
		cursor: pointer;
		border: none;
		background: rgba(0, 0, 0, 0.25);
		background: url(../images/btn-next.png) no-repeat center center;
		background-size: contain;
		opacity: 0.7;
		padding: 20px;

		@media (max-width: 767.9px) {
			width: 40px;
			height: 40px;
		}
	}

	&:hover {
		opacity: 0.5;
	}
}

.upload-picture {
	position: relative;
	z-index: 100;
	display: flex;
	align-items: center;
	align-self: start;
	flex-direction: column;
	padding: 10px;
	cursor: pointer;
	transition: opacity .25s ease;
	border-radius: 12px;
	background-color: rgba(0,0,0,.5);
	margin: 10px;

	@media (max-width: 767.9px) {
		width: calc(100% - 20px);
		align-self: center;
		margin: 10px auto;
	}

	input {
		display: none;
	}

	h2 {
		font-size: 16px;
		line-height: 1.3;
		max-width: 120px;
		margin: 0;
		margin-bottom: 10px;
		text-align: center;
		color: rgba(255, 255, 255, 0.75);
	}
}

.lightbox-drag-animation-area {
	position: absolute;
	width: 264px;
	height: 128px;
	overflow: hidden;
	display: flex;
	top: 50%;
	transform: translateY(-50%);
	left: 30%;

	@media (max-width: 767.9px) {
		display: none;
	}

	.drag-icon {
		opacity: 0.75;
		width: 64px;
		height: 64px;
		-webkit-animation: drag-animation 1.5s linear infinite alternate;
		animation: drag-animation 1.5s linear infinite alternate;
	}
}

@keyframes drag-animation {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(200px, 32px);
	}
}

@-webkit-keyframes drag-animation {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(200px, 64px);
	}
}

.cropper-rotate-btn {
	position: absolute;
	right: 10px;
	bottom: 10px;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: rgba(0, 0, 0, 0.5);
	border: none;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	z-index: 100;
	transition: background-color 0.3s;
    border: 1px solid white;

	&:hover {
		background: rgba(0, 0, 0, 0.8);
	}

	svg {
		width: 24px;
		height: 24px;
	}
}

.cropper-container {
	@media (min-width: 768px) {
		max-width: 500px !important;
		max-height: 500px !important;
		
	}

	@media (max-width: 767.9px) {
		max-width: 300px !important;
		max-height: 300px !important;
	}

	.cropper-view-box {
		outline: none;
		border-radius: 0;
	}

	.cropper-face {
		background: transparent;
	}
}

.modal {
	&__content {
		.cropper-container {
			margin: 0 auto;
		}
	}
}

.cropper-crop-box {
	transform: translate(0, 0) !important;
	width: 100% !important;
	height: 100% !important;
	max-width: 500px !important;
	max-height: 500px !important;

	@media (max-width: 767.9px) {
		max-width: 300px !important;
		max-height: 300px !important;
	}
}

.cropper-container.cropper-bg {
	overflow: hidden !important;
}

.lightbox-video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: black;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;

    &__video {
        width: auto;
        height: 100%;
        max-width: 100%;
        object-fit: cover;
        object-position: center;
        opacity: 1;
        transition: opacity 0.5s ease-in-out;
        will-change: opacity;
    }
}