.quiz {
	position: fixed;
	z-index: 9000;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: #131313;
	height: 100% !important;
	overflow: hidden;

	.error-message {
		color: #ff3434;
		margin: 0;
		position: absolute;
		top: 12px;
		left: 0;
	}

	.button-primary,
	.btn--primary {
		&::before {
			display: none;
		}
	}

	.glide__slides {
		height: 100%;
		list-style: none;
		padding-left: 0;
	}

	.glide__slide {
		height: 100%;
	}

	.glide__track {
		height: 100%;
	}

	&__form {
		height: 100%;
	}

	&__screen {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		overflow: hidden;
		padding: 20px 20px 20px;
		height: calc(100% - 40px);
		max-width: 450px;
		margin-left: auto;
		margin-right: auto;
		height: 100%;
		width: 100%;
		box-sizing: border-box;

		min-height: 0;

		@media (min-width: 768px) {
			max-width: 550px;
		}
	}

	&__button-back {
		align-self: flex-start;
		font-size: 0;
		border: none;
		background: url('/images/icon-btn-back.svg') no-repeat center center;
		width: 24px;
		height: 24px;
		cursor: pointer;

		@media (min-width: 768px) {
			width: 36px;
			height: 36px;
		}
	}

	&__question {
		margin-top: 20px;
		margin-bottom: 0;

		@media (min-width: 768px) {
			font-size: 26px;
		}
	}

	&__options {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		gap: 12px;
		position: relative;
		flex-grow: 1;
		max-height: 700px;
		overflow-y: auto;
		margin: 0;
		padding-top: 40px;
		min-height: 0;
		-ms-overflow-style: none;
		scrollbar-width: none;
		padding-bottom: 40px;

		&::-webkit-scrollbar {
			display: none;
		}
	}

	&__options-wrapper {
		&--choice-image {
			display: flex;
			flex-wrap: wrap;
			gap: 20px;

			.quiz-option {
				width: 48%;
				overflow-x: hidden;

				@media (max-width: 767.9px) {
					box-sizing: border-box;
					width: 45%;
					align-self: stretch;
				}

				&__label {
					display: flex;
					flex-direction: column;

					&::before {
						display: none;
					}
				}

				&__choice-image-wrapper {
					position: relative;
				}

				&__header {
					margin: 0 auto 10px;
					font-size: 18px;
					line-height: 1.2;
					font-weight: 500;
					text-align: center;

					@media (max-width: 767.9px) {
						font-size: 16px;
					}
				}
			}

			.option-image {
				width: 100%;
				height: auto;
				z-index: 2;
				position: relative;
			}

			.option-image-checked {
				position: absolute;
				bottom: 0;
				left: 0;
				top: 0;
				right: 0;
				width: 100%;
				height: auto;
				opacity: 0;
				z-index: 3;
				transition: opacity 0.3s ease;
			}

			input[type='radio']:checked + .quiz-option__label--choice-image {
				.option-image-checked {
					-webkit-animation: image-on-blink 0.75s ease-in-out;
					animation: image-on-blink 0.75s ease-in-out;
					-webkit-animation-fill-mode: forwards;
					animation-fill-mode: forwards;
				}

				.option-image {
					-webkit-animation: image-off-blink 0.75s ease-in-out;
					animation: image-off-blink 0.75s ease-in-out;
					-webkit-animation-fill-mode: forwards;
					animation-fill-mode: forwards;
				}

				.option-image-user {
					-webkit-animation: image-user-blink 0.75s ease-in-out;
					animation: image-user-blink 0.75s ease-in-out;
					-webkit-animation-fill-mode: forwards;
					animation-fill-mode: forwards;
					-webkit-filter: brightness(150%);
					filter: brightness(150%);
				}

				.quiz-option__header {
					color: #131313;
				}
			}

			.option-image-user {
				width: 57.5%;
				height: auto;
				position: absolute;
				z-index: 1;
				top: 5.5%;
				right: 3.5%;
				-webkit-animation-fill-mode: forwards;
				animation-fill-mode: forwards;
				-webkit-filter: brightness(50%);
				filter: brightness(50%);
			}
		}
	}

	&-option {
		position: relative;
		border-radius: 12px;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		cursor: pointer;

		&__input[type='radio'] {
			position: absolute;
			left: -9999px;
		}

		&__label {
			padding: 12px 16px;
			background-color: rgb(34, 32, 32);
			display: flex;
			border-radius: 12px;
			align-items: center;
			justify-content: flex-start;
			gap: 10px;
			transition: background-color 0.25s ease-in-out;
			font-size: 17px;
			letter-spacing: 0.75px;
			cursor: pointer;

			@media (max-width: 767.9px) {
				font-size: 15px;
				align-self: stretch;
				height: 100%;
				justify-content: space-between;
			}

			@media (min-width: 768px) {
				font-size: 17px;
			}

			&::before {
				content: '';
				display: block;
				width: 24px;
				height: 24px;
				border-radius: 50%;
				background: url('/images/icon-radio-not-checked.svg') no-repeat center
					center;
				background-size: contain;
				transition: background 0.25s ease-in-out;

				@media (min-width: 768px) {
					width: 26px;
					height: 26px;
				}
			}

			&-other--filled {
				&::before {
					background: url('/images/icon-radio-checked.svg') no-repeat center
						center !important;
				}
			}
		}

		&__input-textarea,
		&__input-text:not(.quiz-option__input-other) {
			color: #ffffff;
			border-radius: 12px;
			background-color: rgba(255, 255, 255, 0.1);
			border: 1px solid rgba(255, 255, 255, 0.2);
			padding: 12px 19px;
			font-size: 17px;
			outline: none;

			&::-webkit-input-placeholder {
				color: rgba(243, 243, 243, 0.8);
				font-style: italic;
				font-size: 17px;
				font-weight: 300;
			}

			&::-moz-placeholder {
				color: rgba(243, 243, 243, 0.8);
				font-style: italic;
				font-size: 17px;
				font-weight: 300;
			}

			&:-ms-input-placeholder {
				color: rgba(243, 243, 243, 0.8);
				font-style: italic;
				font-size: 17px;
				font-weight: 300;
			}

			&::-ms-input-placeholder {
				color: rgba(243, 243, 243, 0.8);
				font-style: italic;
				font-size: 17px;
				font-weight: 300;
			}

			&::placeholder {
				color: rgba(243, 243, 243, 0.8);
				font-style: italic;
				font-size: 17px;
				font-weight: 300;
			}

			&:focus {
				outline: none;
			}
		}

		&__amount-of-money {
			position: relative;

			input[type='number'] {
				padding-left: 30px;
			}

			&::before {
				content: '$';
				position: absolute;
				top: 12px;
				font-size: 18px;
				left: 15px;
			}
		}

		&__input[type='radio']:checked + &__label:not(.quiz-option__label-other) {
			background-color: #fcc400;
			-webkit-animation: choice-label-blink 0.75s ease-in-out;
			animation: choice-label-blink 0.75s ease-in-out;
			-webkit-animation-fill-mode: forwards;
			animation-fill-mode: forwards;

			&::before {
				background: url('/images/icon-radio-checked.svg') no-repeat center
					center;
				background-size: contain;
			}
		}

		&__input[type='radio']:checked + &__label.quiz-option__label-other {
			background-color: #fcc400;

			&::before {
				background: url('/images/icon-radio-checked.svg') no-repeat center
					center;
				background-size: contain;
			}
		}

		&-other {
			.quiz-option__input-other {
				display: none;
			}

			.quiz-option__input[type='radio']:checked + .quiz-option__label {
				font-size: 0;
				flex-direction: row-reverse;

				.quiz-option__input-other {
					display: block;
					border: none;
					background-color: transparent;
					outline: none;
					font-size: 17px;
					line-height: 1.3;
					letter-spacing: 0.24px;
					color: #f3f3f3;
					font-style: italic;
					font-weight: 500;
					text-align: left;
					flex-grow: 1;

					&::-webkit-input-placeholder {
						color: #f3f3f3;
					}

					&::-moz-placeholder {
						color: #f3f3f3;
					}

					&:-ms-input-placeholder {
						color: #f3f3f3;
					}

					&::-ms-input-placeholder {
						color: #f3f3f3;
					}

					&::placeholder {
						color: #f3f3f3;
					}
				}

				&::before {
					background: url('/images/icon-radio-not-checked.svg') no-repeat center
						center;
					background-size: contain;
				}
			}
		}

		&__input[type='radio']:checked + .quiz-option__label {
			&-audio-choice {
				&::after {
					content: '';
					display: block;
					width: 24px;
					height: 24px;
					background: url('/images/icon-audio-choice.svg') no-repeat center
						center;
					background-size: contain;
					margin-left: auto;
				}
			}
		}
	}

	&__footer {
		padding: 12px 0;
		border-top: 1px solid rgba(255, 255, 255, 0.2);
		margin-top: 40px;
		display: flex;
		justify-content: space-between;
		gap: 15px;
		box-shadow: 0px -30px 20px 10px rgba(19, 19, 19, 1);
		overflow-y: visible;
		z-index: 5000;

		@media (min-width: 1080px) {
			margin-top: 0;
			margin-bottom: 20px;
		}
	}

	&__button-next,
	&__button-submit {
		font-size: 17px;
		line-height: 1.5;
		letter-spacing: 0.5px;
		font-weight: 600;
		flex-grow: 1;
	}

	&__button-skip {
		font-size: 17px;
		line-height: 1.3;
		letter-spacing: 0.5px;
		font-weight: 600;
		flex-grow: 0;
		border: none;
		box-shadow: 0 2.6666666667px 8px 0 rgba(255, 255, 255, 0.79) !important;
	}

	&__post-field-description {
		margin: 55px 0 auto 6px;
		padding: 9px 15px 46px 20px;
		color: rgba(255, 255, 255, 0.5);
		font-size: 17px;
		line-height: 1.6;
		font-weight: normal;
		border-left: 1px solid #3c3c50;
		position: relative;

		&::after {
			display: block;
			content: '';
			position: absolute;
			top: -12px;
			left: -6px;
			width: 12px;
			height: 12px;
			background: url('/images/icon-form-description-circle.svg') no-repeat
				center center;
			background-size: contain;
		}
	}

	&-loading {
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 200;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(19, 19, 19, 0.75);

		&--dark {
			background: rgba(19, 19, 19, 1);
		}
	}

	&-option__input.quiz-option__input-text.state {
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		background-color: #2b2b2b;
		color: #ffffff;
		border: 1px solid #595959;
		width: 100%;
		font-size: 16px;
		outline: none;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath d='M1 4l5 5 5-5' fill='none' stroke='%23ffffff' stroke-width='1.5'/%3E%3C/svg%3E");
		background-repeat: no-repeat;
		background-position: right 15px center;
		cursor: pointer;
		transition: border-color 0.3s ease;

		&:focus {
			border-color: #fcc400;
			box-shadow: 0 0 5px rgba(252, 196, 0, 0.3);
		}

		option {
			background-color: #2b2b2b;
			color: #ffffff;
			
			&:disabled {
				color: #6f6e6e;
			}

			&:checked {
				background-color: #fcc400;
				color: #131313;
			}
		}
	}
}

.sticky-button-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.sticky-button {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  max-width: 90%;
}

@-webkit-keyframes choice-label-blink {
	0% {
		background-color: #1e1e32;
	}

	20% {
		background-color: #fcc400;
	}

	40% {
		background-color: #1e1e32;
	}

	60% {
		background-color: #fcc400;
	}

	80% {
		background-color: #1e1e32;
	}

	100% {
		background-color: #fcc400;
	}
}

@keyframes choice-label-blink {
	0% {
		background-color: #1e1e32;
	}

	20% {
		background-color: #fcc400;
	}

	40% {
		background-color: #1e1e32;
	}

	60% {
		background-color: #fcc400;
	}

	80% {
		background-color: #1e1e32;
	}

	100% {
		background-color: #fcc400;
	}
}

@-webkit-keyframes image-user-blink {
	0% {
		-webkit-filter: brightness(100%);
		filter: brightness(100%);
	}

	20% {
		-webkit-filter: brightness(150%);
		filter: brightness(150%);
	}

	40% {
		-webkit-filter: brightness(100%);
		filter: brightness(100%);
	}

	60% {
		-webkit-filter: brightness(150%);
		filter: brightness(150%);
	}

	80% {
		-webkit-filter: brightness(100%);
		filter: brightness(100%);
	}

	100% {
		-webkit-filter: brightness(150%);
		filter: brightness(150%);
	}
}

@keyframes image-user-blink {
	0% {
		-webkit-filter: brightness(100%);
		filter: brightness(100%);
	}

	20% {
		-webkit-filter: brightness(150%);
		filter: brightness(150%);
	}

	40% {
		-webkit-filter: brightness(100%);
		filter: brightness(100%);
	}

	60% {
		-webkit-filter: brightness(150%);
		filter: brightness(150%);
	}

	80% {
		-webkit-filter: brightness(100%);
		filter: brightness(100%);
	}

	100% {
		-webkit-filter: brightness(150%);
		filter: brightness(150%);
	}
}

@-webkit-keyframes image-on-blink {
	0% {
		opacity: 0;
	}

	20% {
		opacity: 1;
	}

	40% {
		opacity: 0;
	}

	60% {
		opacity: 1;
	}

	80% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes image-on-blink {
	0% {
		opacity: 0;
	}

	20% {
		opacity: 1;
	}

	40% {
		opacity: 0;
	}

	60% {
		opacity: 1;
	}

	80% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@-webkit-keyframes image-off-blink {
	0% {
		opacity: 1;
	}

	20% {
		opacity: 0;
	}

	40% {
		opacity: 1;
	}

	60% {
		opacity: 0;
	}

	80% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@keyframes image-off-blink {
	0% {
		opacity: 1;
	}

	20% {
		opacity: 0;
	}

	40% {
		opacity: 1;
	}

	60% {
		opacity: 0;
	}

	80% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

.address-autocomplete {
	width: 100%;
	padding: 10px;
	font-size: 16px;
	border: 1px solid #ccc;
	border-radius: 12px;
	outline: none;

	&:focus {
		border-color: #fcc400;
	}
}

.address-input-wrapper {
	.page-loading__spinner {
		margin-left: auto;
		margin-right: auto;
		display: block;
	}

	&.hidden {
		display: none;
	}
}

.quiz-option__input-text.address-input-active-suggestions,
.address-input--searching {
	border-radius: 12px 12px 0px 0px !important;
	border-color: #fcc400;
}

.header-normal-text {
	font-weight: normal;
	margin-left: 5px;
	display: block;
}

.address-suggestions {
	list-style-type: none;
	padding: 0;
	margin: 0;
	max-height: 500px;
	border-radius: 0 0 12px 12px;
	background-color: #2b2b2b;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	overflow: hidden;

	.address-suggestion {
		padding: 8px 19px;
		font-size: 17px;
		cursor: pointer;
		transition: background-color 0.2s;
		background: #2b2b2b;
		color: #fff;
		border-bottom: 1px solid #595959;

		&:hover,
		&.selected {
			color: #131313;
			background: #fcc400;
		}

		&:last-child {
			border-bottom: 0;
		}

		&.address-not-in-list {
			font-style: italic;
			background: #404040;
			color: #858585;
			border-bottom: 1px solid #595959;
		}
	}
}

#full-address,
#latitude,
#longitude {
	display: none;
}

.quiz__footer .btn {
	box-shadow: none !important;
}

.additional-address-fields {
	display: flex;
	flex-wrap: wrap;
	gap: 15px;
	justify-content: space-between;

	input,
	select {
		width: 47%;
		justify-content: space-between;

		&::-webkit-input-placeholder {
			color: #6f6e6e !important;
			opacity: 1;
		}

		&::-webkit-input-placeholder,
		&::-moz-placeholder,
		&:-ms-input-placeholder,
		&::-ms-input-placeholder,
		&::placeholder {
			color: #6f6e6e !important;
			opacity: 1;
		}

		&::-ms-input-placeholder {
			color: #5c5c5c !important;
		}
	}
}

ul#orderDetails {
	list-style: none;
	padding-left: 0;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.payment-processing {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: auto;
	margin-top: auto;
	margin-bottom: auto;
}

.additional-address-fields input,
.additional-address-fields select {
	width: 100%;
}

.payment-error-image {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

#paymentStatus {
	text-align: center;
}

.quiz-features-screen__list {
	list-style: none;
	padding-left: 0;

	li {
		display: flex;
		margin-bottom: 10px;
		gap: 8px;
		
		&::before {
			content: "\f058";
		font-family: "FontAwesome";
		  font-size: 20px;
			margin-right: 8px;
			  font-weight: 900;
			  color: #fff;
			display: block;
			width: 20px;
			text-align: center;
			flex-shrink: 0;
		}
	}
}

.quiz-features-screen__rating {
	margin: 10px auto;
	display: flex;
	flex-direction: column;
	align-items: center;

	&-title {
		margin-bottom: 10px;
		font-size: 30px;
		line-height: 1.2;
	}

	&-block {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&-stars {
		display: flex;
		gap: 5px; 
		margin-right: 15px;

		span.fa-star {
			font-size: 20px;
			color: #2db783;
		}
	}

	&-text {
		font-weight: 600;
	}
}

#designer-contact-needed .quiz__question {
	font-size: 20px;

}

#designer-contact-needed  .quiz-option__label {
	justify-content: flex-start;
}

.quiz-name-inputs .quiz-option__input-text.first-name {
	margin-bottom: 15px !important;
}