.accordion-item {
	border-radius: 12px;
	margin: 0 auto 15px;
	overflow: hidden;
	cursor: pointer;
		
	@media (max-width: 767.9px) {
		width: 100%;
		max-width: 100%;
	}

	&__title {
		border-bottom: 1px dashed rgba(255, 255, 255, 0.25);

		color: #f3f3f3;
		font-weight: 400;
		margin: 0 auto;
		display: flex;
		align-items: center;
		padding: 5px;
		margin-bottom: 6px;
		font-size: 16px;
		line-height: 1.5;
		letter-spacing: 0.1em;

		
		@media (max-width: 767.9px) {
			font-size: 18px;
			letter-spacing: 0.5px;
			line-height: 1.6;
		}

		&::before {
			transform: rotate(180deg);
			content: '';
			display: block;
			background: url('/images/icon-accordion-arrow.svg') no-repeat center
				center;
			width: 14px;
			height: 14px;
			margin-left: 5px;
			margin-right: 5px;
			background-size: contain;
			flex-shrink: 0;
			transition: transform 0.25s ease;
		}
	}

	&__answer {
		transition:
			max-height 0s ease,
			opacity 0s ease;
		max-height: 0;
		padding-left: 14px;
		padding-right: 12px;
		opacity: 0;
		overflow: hidden;
	}

	&__text {
		margin: 0 auto;
		font-weight: 300;
		line-height: 1.5;
		font-size: 14px;
		letter-spacing: 0em;
		padding: 5px;

		
		@media (max-width: 767.9px) {
			font-size: 16px;
			letter-spacing: 0.5px;
			line-height: 1.6;
		}
	}

	&--active {
		.accordion-item__title {
			&::before {
				transform: rotate(360deg);
			}
		}

		.accordion-item__answer {
			max-height: 1500px;
			opacity: 1;
			transition:
				max-height 0 ease,
				opacity 0 ease;
		}
	}
}
