.image-gallery {
	position: relative;

	@media (max-width: 767.9px) {
		display: flex;
		flex-wrap: wrap;
	}

	&__track {
		overflow: hidden;
		
		@media (max-width: 767.9px) {
			width: 100%;
			order: -1;
		}
	}

	&__slides {
		display: flex;
		list-style: none;
		margin: 0;
		padding: 0;
	}

	&__item {
		flex-shrink: 0;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
	}

	&__item-content-wrapper {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: repeat(2, 300px);
		width: 100%;
		
		@media (max-width: 767.9px) {
			grid-template-columns: repeat(1, 1fr);
			grid-template-rows: repeat(1, 400px);
		}
	}

	&__image-wrapper {
		display: block;
		height: auto;
		display: flex;
		flex-direction: column;
		background-size: cover;
		background-position: center center;

		margin: 10px;
		overflow: hidden;

		&--big-square {
			grid-column: span 2;
			grid-row: span 2;
		}

		&--small-square {
			grid-column: span 1;
			grid-row: span 1;
		}

		&--long {
			grid-column: span 2;
			grid-row: span 1;
		}
	}

	&__image {
		width: auto;
		height: 100%;
	}

	&__control {
		position: absolute;
		top: 48%;
		transform: translateY(-50%);
		z-index: 2;

		@media (max-width: 767.9px) {
			position: static;
			transform: translate(0,0);
			width: 50%;
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}

		&--prev {
			left: 0;
			transform: translateX(-110%) rotate(180deg) translateY(25%);

			@media (max-width: 767.9px) {
				transform: rotate(180deg) translateX(-10%);
				order: 1;
			}
		}

		&--next {
			right: 0;
			transform: translateX(0);
			

			@media (min: 767.9px) {
			    transform: translateX(100%);
			}
			

			@media (max-width: 767.9px) {
				justify-content: flex-end;
				transform: translateX(-10%);
				order: 2;
			}
		}
	}

	&__control-button {
		border: none;
		color: white;
		cursor: pointer;
		font-size: 0;
		transition: opacity 0.3s ease;
		border-radius: 50%;
		display: block;
		background: url('/images/slider-arrow.png') no-repeat center center;
		width: 40px;
		height: 50px;
		background-size: contain;

		&:hover {
			opacity: 0.5;
		}
	}

	&__pagination.glide__bullets {
		display: flex;
		gap: 10px;
		justify-content: center;
		margin-top: 25px;
	}

	&__pagination-item {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background-color: rgba(255, 255, 255, 0.2);
		border: none;
		cursor: pointer;
		padding: 0;
		transition: background-color 0.3s;

		&:hover,
		&.glide__bullet--active {
			background-color: rgb(22, 102, 241);
		}
	}
}

.glide {
	
	&__slides {
		margin: 0;
	}

	&__arrow {
		border: none;
		padding: 0;
	}

	&__bullets {
		margin-top: 10px;
	}
}

.image-gallery-item {
	border-radius: 11px;
	overflow: hidden;

	&__header {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 12px 12px 5px;

		@media (min-width: 768px) {
			padding: 24px 24px 10px;
		}
	}

	&__photo {
		overflow: hidden;
		border-radius: 50%;
		width: 70px;
		height: 70px;
		-o-object-fit: cover;
		object-fit: cover;

		@media (min-width: 768px) {
			width: 100px;
			height: 100px;
			margin-bottom: 15px;
		}
	}

	&__name {
		font-size: 17px;
		line-height: 1.75;
		letter-spacing: 0.75px;
		font-weight: 400;
		margin: 0 auto;

		@media (min-width: 768px) {
			font-size: 19px;
		}
	}

	&__blockquote {
		margin: 0 auto;
		padding: 15px 10px 6px;
		background-color: #d9d9d9;
		font-weight: 900;
		font-style: italic;
		font-size: 17px;
		line-height: 1.5;
		color: #0c0516;
		letter-spacing: 0.75px;
		text-align: center;

		@media (min-width: 768px) {
			padding: 30px 60px 26px;
			font-size: 18px;
		}
	}

	&__quote {
		margin: 0 auto;
	}
}
