#page-loading {
	position: fixed;
	z-index: 9999 !important;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	display: flex;
	align-items: center;
	justify-content: center;

	background: rgba(19, 19, 19, 1);
}

.page-loading-animation,
.page-loading-animation div {
	box-sizing: border-box;
}

.page-loading-animation {
	position: relative;

	display: inline-block;

	width: 80px;
	height: 80px;
}

.page-loading-animation div {
	position: absolute;
	left: 8px;

	display: inline-block;

	width: 16px;

	-webkit-animation: page-loading-animation 1.2s cubic-bezier(0, 0.5, 0.5, 1)
		infinite;
	animation: page-loading-animation 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;

	background: #596bc1;
}

.page-loading-animation div:nth-child(1) {
	left: 8px;

	-webkit-animation-delay: -0.24s;
	animation-delay: -0.24s;
}

.page-loading-animation div:nth-child(2) {
	left: 32px;

	-webkit-animation-delay: -0.12s;
	animation-delay: -0.12s;
}

.page-loading-animation div:nth-child(3) {
	left: 56px;

	-webkit-animation-delay: 0s;
	animation-delay: 0s;
}

@-webkit-keyframes page-loading-animation {
	0% {
		top: 8px;

		height: 64px;
	}

	50%,
	100% {
		top: 24px;

		height: 32px;
	}
}
@keyframes page-loading-animation {
	0% {
		top: 8px;

		height: 64px;
	}

	50%,
	100% {
		top: 24px;

		height: 32px;
	}
}

.page-loading-animation,
.page-loading-animation div {
	box-sizing: border-box;
}
.page-loading-animation {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.page-loading-animation div {
	display: inline-block;
	position: absolute;
	left: 8px;
	width: 16px;
	background: #131313;
	-webkit-animation: page-loading-animation 1.2s cubic-bezier(0, 0.5, 0.5, 1)
		infinite;
	animation: page-loading-animation 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.page-loading-animation div:nth-child(1) {
	left: 8px;
	-webkit-animation-delay: -0.24s;
	animation-delay: -0.24s;
}
.page-loading-animation div:nth-child(2) {
	left: 32px;
	-webkit-animation-delay: -0.12s;
	animation-delay: -0.12s;
}
.page-loading-animation div:nth-child(3) {
	left: 56px;
	-webkit-animation-delay: 0s;
	animation-delay: 0s;
}
@-webkit-keyframes page-loading-animation {
	0% {
		top: 8px;
		height: 64px;
	}
	50%,
	100% {
		top: 24px;
		height: 32px;
	}
}
@keyframes page-loading-animation {
	0% {
		top: 8px;
		height: 64px;
	}
	50%,
	100% {
		top: 24px;
		height: 32px;
	}
}
